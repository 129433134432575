body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hubContainer {
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}

.hub-item {
  display: flex;
  padding: 10px;
  background-color: teal;
  border: 1px solid black;
  color: white;
  border-radius: 5px;
  width: 20%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.back {
  padding: 20px;
  padding-left: 22px;
  padding-bottom: 18px;
  cursor: pointer;
  height: 25px;
  width: 30px;
}

.graph-continer {
  display: flex;
  margin-top: 20px;
  height: 80vh;
  flex-direction: column;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.nav-continer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headings {
  flex-direction: column;
  height: 100%;
  font-weight: bold;
  font-size: large;
}

.details {
  flex-direction: column;
  height: 100%;
  margin-left: 50px;
  font-weight: medium;
}

.items {
  margin-top: 5px;
}
